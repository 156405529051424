/* global $ */

class App
{
    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initBannerSlider();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initFontAwesome();
        this.initForms();
        this.initMatrixBlock();
        this.initToggleNav();
        $('body').css('opacity', 1);
        $(window).scroll(() => {
            this.initStickyMenu();
        });
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }
    initBannerSlider() {
        if ($(".bnr-img").length > 1) {
            import(/* webpackChunkName: "slick" */ './components/slick').then(() => {
                $('.banner-slider').slick();
            });
        }
    }
    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }
    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }
    initForms()
    {
        if ($('form').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($('form.js-validate'), (i, elem) => {
                    new chunk.Form(elem.id);
                });
            });
        }
        if ($('form .datepicker').length) {
            import(/* webpackChunkName: "flatpickr" */ './components/flatpickr').then((chunk) => {
                $.each($(".datepicker"),(i, elem) => {
                    chunk.flatpickr(elem, $(elem).data('options'));
                });
            });
        }
    }
    initToasts(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Toast(elem);
            });
        });
    }
    initTooltips(elems)
    {
        this.getBootstrap().then((chunk) => {
            $.each(elems, function (i, elem) {
                new chunk.Tooltip(elem);
            });
        });
    }
    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 53) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
    }
    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }
    initToggleNav() {
        $(".navbar-toggler").on('click', function () {
            $('body').toggleClass("menuOpen");
            $('.navbar-collapse').toggleClass("open");
        });
    }
}

export default App;